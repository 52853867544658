import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="flex flex-col items-center gap-12">
      <h1>404: Not Found</h1>
      <div>
        <StaticImage src="../images/icon.png" />
      </div>
      <p>You just hit a route that doesn&#39;t exist... keep searching you are almost there.</p>
    </div>
  </Layout>
)

export default NotFoundPage
